const parseToJson = (value) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export {
  parseToJson
}