const PRESALES = "presales";
const levelsConfig = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Error",
    value: "error",
  },
  {
    text: "Info",
    value: "info",
  },
  {
    text: "Debug",
    value: "debug",
  }
];

const domains = {
  dev: process.env.REACT_APP_DEV_HOST + "/common/api/be-query-log",
  uat: process.env.REACT_APP_UAT_HOST + "/legacy/api/be-query-log",
  presales: process.env.REACT_APP_PRESALE_HOST + "/common/api/be-query-log",
  demo: process.env.REACT_APP_DEMO_HOST + "/common/api/be-query-log",
};

const envConfig = [
  {
    text: "DEV",
    value: "dev",
  },
  {
    text: "UAT",
    value: "uat",
  },
  {
    text: "PRESALES-DEV",
    value: "presales",
  },
  {
    text: "DEMO-PROD",
    value: "demo",
  },
];

const serviceKeys = [
  {
    key: "Solution-Optimizer",
    name: "SOLUTION GENERATOR",
  },
  {
    key: "Product-Selector",
    name: "PRODUCT SELECTOR",
  },
  {
    key: "HappiU",
    name: "HAPPIU",
  },
  {
    key: "Scenario-Visualizer",
    name: "SCENARIO VISUALIZER",
  },
  {
    key: "Product-Optimizer",
    name: "PRODUCT OPTIMIZER",
  },
  {
    key: "PE",
    name: "PE",
  },
  {
    key: "Mira-Insight",
    name: "MIRA INSIGHT",
  },
];

export { levelsConfig, domains, envConfig, serviceKeys, PRESALES };
