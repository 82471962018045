import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    maxWidth: 1400,
  },
  cardItem: {
    padding: 20,
    marginRight: 20,
    maxHeight: 100,
    width: 900,
    overflow: 'auto',
  },
  formControl: {
    minWidth: 120,
  },
})

export default useStyles;