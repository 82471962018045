import {
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const login = async () => {
    const url = `${process.env.REACT_APP_DEV_HOST}/admins/api/v1/ap/auth/token`;
    const payload = { username, password };
    const headers = {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY_DEV,
    };
    try {
      setIsLoading(true);
      const res = await axios.post(url, payload, { headers });
      sessionStorage.setItem("token", JSON.stringify(res.data));
      setIsLoading(false);
      onLoginSuccess();
    } catch (error) {
      setIsLoading(false);
      alert("Wrong username password");
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Typography variant="h1" style={{ color: "#bc6c25", padding: 40 }}>
        Login
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}
      >
        <Grid container item direction="column" spacing={3}>
          <Grid item style={{ width: 300 }}>
            <TextField
              label="Username"
              color="primary"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Password"
              color="primary"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isLoading}
              color="primary"
            >
              {`${isLoading ? "Submitting ..." : "Submit"}`}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default Login;
