import { Container, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import BugReportIcon from "@material-ui/icons/BugReport";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputIcon from "@material-ui/icons/Input";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useClipboard } from "use-clipboard-copy";
import "./App.css";
import {
  domains,
  envConfig,
  levelsConfig,
  PRESALES,
  serviceKeys,
} from "./config";
import Login from "./Login";
import useStyles from "./styles";
import { parseToJson } from "./utils";
import Slide from "@material-ui/core/Slide";

const App = () => {
  const [sessionId, setSessionId] = useState("");
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [hour, setHour] = useState(168);
  const [env, setEnv] = useState("dev");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const clipboard = useClipboard();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [level, setLevel] = useState("all");

  if (!isLoggedIn) {
    return (
      <Login
        onLoginSuccess={() => {
          setIsLoggedIn(true);
        }}
      />
    );
  }

  const search = (id) => {
    if (!id) {
      return;
    }
    const url = domains[env];

    const prefix = env === PRESALES ? PRESALES : "helium";
    const payload = {
      filename: `${prefix}/${id}.log`,
      hour: parseFloat(hour) || 10,
      limit: limit,
      order: order,
      level: level === "all" ? null : level,
    };
    setIsLoading(true);

    let headers = {
      "Content-Type": "application/json",
    };

    if (env === PRESALES) {
      headers["Ocp-Apim-Subscription-Key"] =
        process.env.REACT_APP_API_KEY_PRESALES;
    }

    axios
      .post(url, { ...payload }, { headers })
      .then((res) => {
        const tmp = [];
        let result = _.orderBy(
          res.data,
          (x) => new Date(x.timestamp).getTime(),
          order
        );
        result.forEach((item, index) => {
          const content =
            typeof item.message.content === "string" ||
            item.message.request === "string"
              ? parseToJson(item.message.content || item.message.request)
              : item.message.content || item.message.request;

          tmp.push({
            timestamp: item.timestamp,
            level: item.level,
            title: item.message.title,
            content,
          });
        });
        setData(tmp);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        sessionStorage.clear();
        window.location.reload();
        alert("something went wrong, please try later.");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCopy = (data) => {
    clipboard.copy(JSON.stringify(data));
  };

  const handleDownload = (payload, title) => {
    const result = serviceKeys.find((s) => title.includes(s.name));
    const data =
      "text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(payload, null, 2));
    const a = document.createElement("a");
    a.href = "data:" + data;
    a.download = `${result.key}-${sessionId}-${new Date().getTime()}.json`;
    a.click();
  };

  const levelMenuItem = (input) => {
    switch (input.value) {
      case "error":
        return (
          <MenuItem value={input.value} key={input.value}>
            <Grid item container style={{ alignItems: "center" }}>
              <WarningIcon
                fontSize="small"
                style={{ paddingRight: 5, color: "red" }}
              />
              {input.text}
            </Grid>
          </MenuItem>
        );
      case "info":
        return (
          <MenuItem value={input.value} key={input.value}>
            <Grid item container style={{ alignItems: "center" }}>
              <InfoIcon
                fontSize="small"
                style={{ paddingRight: 5, color: "blue" }}
              />
              {input.text}
            </Grid>
          </MenuItem>
        );
      case "debug":
        return (
          <MenuItem value={input.value} key={input.value}>
            <Grid item container style={{ alignItems: "center" }}>
              <BugReportIcon
                fontSize="small"
                style={{ paddingRight: 5, color: "brown" }}
              />
              {input.text}
            </Grid>
          </MenuItem>
        );
      default:
        return (
          <MenuItem value={input.value} key={input.value}>
            <Grid item container style={{ alignItems: "center" }}>
              <AllInclusiveIcon fontSize="small" style={{ paddingRight: 5 }} />
              {input.text}
            </Grid>
          </MenuItem>
        );
    }
  };

  // const downloadAllLogs = (e) => {
  //   e.preventDefault();
  //   if (!sessionId) {
  //     return;
  //   }

  //   const type = "json";
  //   const jsonData =
  //     `text/${type};charset=utf-8,` +
  //     encodeURIComponent(JSON.stringify(data, null, 2));
  //   const a = document.createElement("a");
  //   a.href = "data:" + jsonData;
  //   a.download = `${sessionId}-${new Date().getTime()}.${type}`;
  //   a.click();
  // };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const stringifiedLog = JSON.stringify(selectedLog, null, 2);

  const renderDialog = () => {
    return (
      <Grid item>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          scroll={"body"}
          fullWidth={true}
          maxWidth="md"
          keepMounted
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>{stringifiedLog}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button onClick={() => handleCopy(selectedLog)} color="primary">
              Copy
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };
  const renderResult = () => {
    if (isLoading) {
      return <p>Loading ...</p>;
    }
    if (data.length === 0) {
      return <p>No result</p>;
    }

    return data.map((item, index) => {
      return (
        <Grid
          item
          container
          alignItems="center"
          key={index}
          spacing={3}
          style={{ paddingTop: 20 }}
        >
          <Grid item container xs={2} style={{ textAlign: "left" }}>
            <Typography variant="body1">
              {new Date(item.timestamp).toLocaleDateString("en-US") +
                "-" +
                new Date(item.timestamp).toLocaleTimeString()}
            </Typography>
          </Grid>
          <Grid item container xs={6} style={{ textAlign: "left" }}>
            <Typography
              variant="body1"
              style={{ color: item.level === "error" ? "#961b1f" : "auto" }}
            >
              {item.title}
            </Typography>
          </Grid>
          {(item.content || item.request) && (
            <Grid xs={4} item container style={{ justifyContent: "flex-end" }}>
              <Grid item style={{ padding: 5 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpen(true);
                    setSelectedLog(item.content || item.request);
                  }}
                  style={{ backgroundColor: "#439A77" }}
                >
                  <VisibilityIcon
                    fontSize="small"
                    style={{ color: "white", paddingRight: 5 }}
                  />
                  <Typography variant="body2" style={{ color: "white" }}>
                    {"View"}
                  </Typography>
                </Button>
              </Grid>
              <Grid item style={{ padding: 5 }}>
                <Button
                  variant="contained"
                  onClick={() => handleCopy(item.content || item.request)}
                  style={{ backgroundColor: "#0047AB" }}
                >
                  <FileCopyIcon
                    fontSize="small"
                    style={{ color: "white", paddingRight: 5 }}
                  />
                  <Typography variant="body2" style={{ color: "white" }}>
                    {"Copy"}
                  </Typography>
                </Button>
              </Grid>
              <Grid item style={{ padding: 5 }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleDownload(item.content || item.request, item.title)
                  }
                  style={{ backgroundColor: "#FF5733" }}
                >
                  <GetAppIcon
                    fontSize="small"
                    style={{ color: "white", paddingRight: 5 }}
                  />
                  <Typography variant="body2" style={{ color: "white" }}>
                    {"Download"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography
          variant="h2"
          gutterBottom
          style={{
            background: "-webkit-linear-gradient(#00FFFF, #0047AB)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            padding: 40,
          }}
        >
          SYSTEM INTEGRATION ERROR LOGS
        </Typography>

        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingBottom: 20 }}
        >
          <RadioGroup
            aria-label="env"
            name="env"
            value={env}
            onChange={(e) => {
              e.preventDefault();
              setSessionId("");
              setEnv(e.target.value);
            }}
          >
            <Grid container>
              {envConfig.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.text}
                />
              ))}
            </Grid>
          </RadioGroup>
        </Grid>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            search(sessionId);
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <TextField
                style={{ minWidth: 350 }}
                label="Your sessionId"
                color="primary"
                value={sessionId}
                autoFocus
                onChange={(e) => setSessionId(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InputIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
                <Select
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  {levelsConfig.map((t) => levelMenuItem(t))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 80 }}
                label="No. limits"
                color="primary"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      #
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                style={{ width: 80 }}
                label="Hours ago"
                color="primary"
                value={hour}
                type="string"
                onChange={(e) => {
                  const value = e.target.value;
                  const numberRegex =
                    /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
                  if (!value || numberRegex.test(value)) {
                    setHour(e.target.value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ScheduleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Order</InputLabel>
                <Select
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                >
                  <MenuItem value="asc">
                    <Grid item container style={{ alignItems: "center" }}>
                      <ArrowUpwardIcon
                        fontSize="small"
                        style={{ paddingRight: 5}}
                      />
                      Ascending
                    </Grid>
                  </MenuItem>
                  <MenuItem value="desc">
                    <Grid item container style={{ alignItems: "center" }}>
                      <ArrowDownwardIcon
                        fontSize="small"
                        style={{ paddingRight: 5}}
                      />
                      Descending
                    </Grid>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button type="submit">
                <SearchIcon
                  style={{ fontSize: 40, cursor: "pointer", color: "#00008b" }}
                />
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid item container justifyContent="center" alignItems="center">
          {renderResult()}
        </Grid>
        {open && renderDialog()}
      </Grid>
    </Container>
  );
};

export default App;
